/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { StatisticsByDayBatching, StatisticsByDayPicking } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Statistic<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Statistic
     * @name GetGlobalPickingStatistics
     * @request GET:/map/{mapId}/statistic/global
     * @secure
     */
    getGlobalPickingStatistics = (
        mapId: string,
        query?: {
            /** @format date-time */
            from?: string
            /** @format date-time */
            to?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<StatisticsByDayPicking[], any>({
            path: `/map/${mapId}/statistic/global`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Statistic
     * @name GetGlobalBatchingStatistics
     * @request GET:/map/{mapId}/statistic/batching/global
     * @secure
     */
    getGlobalBatchingStatistics = (
        mapId: string,
        query?: {
            /** @format date-time */
            from?: string
            /** @format date-time */
            to?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<StatisticsByDayBatching[], any>({
            path: `/map/${mapId}/statistic/batching/global`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { 
  PagedListResultPathDetailsModel, 
  PagedListResultRequestProductsDetails, 
  StatisticsByDayPicking } from "backend/ApiMiddlewareDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IMiddlewarePickingStore {
  globalStatisticsByDay: StatisticsByDayPicking[];
  loadingList: boolean;
  loadingForm: boolean;
  missions: PagedListResultPathDetailsModel | undefined;
  requestProducts: PagedListResultRequestProductsDetails | undefined;

}

const initialState: IMiddlewarePickingStore = {
  globalStatisticsByDay: [],
  loadingList: false,
  loadingForm: false,
  missions: undefined,
  requestProducts: undefined
};

/**
 * Manage all picking feature from a middleware
 */
const middlewarePickingSlice = createSlice({
  name: "middlewareRequestSlice",
  initialState,
  reducers: {
    setGlobalStatisticsByDay: (state, action: PayloadAction<StatisticsByDayPicking[]>) => {
      state.globalStatisticsByDay = action.payload;
    },
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    setMissions: (state, action: PayloadAction<PagedListResultPathDetailsModel | undefined>) => {
      state.missions = action.payload;
    },
    setRequestProducts: (state, action: PayloadAction<PagedListResultRequestProductsDetails | undefined>) => {
      state.requestProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state = initialState;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: middlewarePickingActions,
  reducer: middlewarePickingReducer,
} = middlewarePickingSlice;

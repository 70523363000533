import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TableColumn } from 'react-data-table-component';
import Vector3Render from 'components/Vector3Render';
import { useAppDispatch, useAppSelector } from 'store/store';
import { createAssetThunk } from 'store/reducers/Admin/ClientMapAsset/thrunks/createAssetThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { loadAssetsThunks } from 'store/reducers/Admin/ClientMapAsset/thrunks/loadAssetsThunks';
import { AssetDetails, FloorUpdate } from 'backend/ApiBackofficeDefinition/data-contracts';
import { updateAssetThunk } from 'store/reducers/Admin/ClientMapAsset/thrunks/updateAssetThunk';
import { deleteAssetThrunks } from 'store/reducers/Admin/ClientMapAsset/thrunks/deleteAssetThrunks';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import ButtonFno from 'components/inputs/ButtonFno';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Modal } from 'react-bootstrap';
import CompareLocationCodeForm from 'components/Admin/forms/CompareLocationCodeForm';
import apiBackofficeDefinition from 'backend/apiBackofficeDefinition';

const AdminClientMapAssets: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapAssets}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const { 
        assets, 
        loadingList, 
        loadingForm } = useAppSelector(state => state.adminMapAsset);

    const [showLocationCodeCompareModal, setShowLocationCodeCompareModal] = useState(false);


    const { clientId, mapId } = useParams();

    useEffect(() => {
        document.title = t('[Admin] Assets de la map');
        if(clientId && mapId && mapId != "new")
        {
            dispatch(loadAssetsThunks(mapId));
        }
    }, [clientId, mapId])

    const onCreate = (asset: AssetDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(createAssetThunk(mapId, asset,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("L'asset a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }
    
    const onUpdate = (asset: AssetDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(updateAssetThunk(mapId, asset,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("L'asset a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onDelete = (asset: AssetDetails, closeModal: () => void) => {
        if(clientId && mapId){

            dispatch(deleteAssetThrunks(mapId, asset.id,{
                onSuccess: (success) => {
                    toast.success(t("L'asset a bien été supprimé"))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
        
    }

    const columns: TableColumn<AssetDetails>[] = [
        {
            id:'locationCode',
            name: t('Location code'),
            sortable: false,
            grow: 2,
            selector: row => row.locationCode,
            cell: (row, index, column, id) => {

                const allCodes = _.filter([row.locationCode, ...row.locationCodes ?? []], val => !!val);


                return <div>
                    {allCodes?.map(code => <Badge key={code} bg="info" className="me-2">{code}</Badge>)}
                </div>
            }
        },
        {
            id:'assetBundleId',
            name: t('Asset bundle id'),
            sortable: true,
            selector: row => row.assetBundleId,
        },
        {
            id:'rotation',
            name: t('Rotation'),
            sortable: false,
            cell: row => <Vector3Render vector={row.rotation} />,
        },
        {
            id:'position',
            name: t('Position'),
            sortable: false,
            cell: row => <Vector3Render vector={row.position} />,
        },
        {
            id:'scale',
            name: t('Scale'),
            sortable: false,
            cell: row => <Vector3Render vector={row.scale} />,
        },
        {
            id:'metadataGroupIds',
            name: t('Nb groupe Metadata'),
            sortable: true,
            selector: row => row.metadataGroupIds.length,
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];

/*
    Fait pour Antoine le 06/01/2025. A supprimer je garde au cas où
    const test = async () => {
        const mapId = "6721033155395b4849566e5b"
        const floors = await apiBackofficeDefinition.floor.floorsList(mapId)
        const floor2 = floors.data.find(floor => floor.id === "6724e53e2553edc1b6b7065e");
        const allAsset = _.filter(assets, asset => _.some(asset.locationCodes, code => code.startsWith("M2")));
        const allAssetIds = allAsset.map(asset => asset.id);

        console.log("AAAA asset", allAssetIds);
        
        floor2?.entityIds.push(...allAssetIds);
        console.log("AAAA floor2", floor2);

        apiBackofficeDefinition.floor.floorPut(mapId, "6724e53e2553edc1b6b7065e", floor2 as FloorUpdate)
    }*/

    return <>
    <TableWithCrudComponent 
            entities={assets} 
            columns={columns}
            loadingForm={loadingForm}
            loadingList={loadingList}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onCreate={onCreate}
            translations={{
                tableTitle: t("Assets de la carte"),
                noEntityText: t("Aucun asset disponible"),
                createTitle: t("Ajouter un Asset"),
                createButtonText: t("Ajouter un Asset"),
                deleteText: (entity) => (t("Voulez-vous supprimer l'asset ?")),
                deleteTitle: (entity) => (t("Supprimer l'asset ") + entity.id),
                updateText: (entity) => (t("Modifier l'asset : ")  + entity.id),
            }}
            customHeader={<>
                <ButtonFno sm className='me-2' onClick={() => setShowLocationCodeCompareModal(true)}><FontAwesomeIcon className='me-2' icon={["fas", "list-check"]} /> {t("Vérifier location code")}</ButtonFno>
            </>}
            fieldSearchable={(entity) => [
                entity.id,
                entity.position.x,
                entity.position.y,
                entity.position.z,
                entity.rotation.x,
                entity.rotation.y,
                entity.rotation.z,
                entity.scale.x,
                entity.scale.y,
                entity.scale.z,
                entity.assetBundleId,
                entity.locationCode,
                entity.locationCodes.join(" "),
                entity.floorId,
            ]}
            initialValueCreateForm={{
                floorId: "",
                mapId: mapId ?? "",
                assetBundleId: "",
                locationCode:"",
                locationCodes: [""],
                position: {
                    "x": 0,
                    "y": 0,
                    "z": 0
                    },
                    rotation: {
                    "x": 0,
                    "y": 0,
                    "z": 0
                    },
                    scale: {
                    "x": 0,
                    "y": 0,
                    "z": 0
                    },
                    metadataGroupIds: [ "" ],
                    assetTemplateId: null
            } as AssetDetails}
            />
            {showLocationCodeCompareModal && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowLocationCodeCompareModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Comparer une liste de location code")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompareLocationCodeForm onCancel={() => setShowLocationCodeCompareModal(false)} 
                                assetsList={assets}/>
                </Modal.Body>
            </Modal>}
            </>;
}

export default AdminClientMapAssets

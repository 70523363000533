import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptimizationBatchDetails, PathDetailsModel, ProcessedProductDetails } from 'backend/ApiMiddlewareDefinition/data-contracts';
import React, { useEffect } from 'react'
import { Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import AceEditor  from "react-ace-builds";
import _ from 'lodash';



interface OwnProps {
    batch: OptimizationBatchDetails;
    pathDetailsClient: PathDetailsModel;
    pathDetailsOptimized: PathDetailsModel | undefined;
    processedProducts: ProcessedProductDetails[];
 }


/**
 * React component to display a table of details of a batch (optimisation path details).
 * @param batch - The batch details.
 * @param pathDetailsClient - The path details for the original path.
 * @param pathDetailsOptimized - The path details for the optimized path.
 * @param processedProducts - The processed products details.
 * @returns The table component.
 */
const MiddlewareBatchTable: React.FC<OwnProps> = ({batch, pathDetailsClient, pathDetailsOptimized, processedProducts}) => {
   const { t } = useTranslation(nameOf({MiddlewareBatchTable}), { useSuspense: false});

   useEffect(() => {
   }, [])


   const renderJsonEditor = (json1: any, json2: any) => {


       return  <AceEditor
       mode="json"
       readOnly
       theme="monokai"
       name="jsonEditor"
       height="600px"
       fontSize={14}
       showPrintMargin={true}
       showGutter={true}
       highlightActiveLine={true}
       value={`// original:
${JSON.stringify(JSON.parse(json1), null, "\t")}
// Optimisé:
${JSON.stringify(JSON.parse(json2), null, "\t")}`}
       setOptions={{
           enableBasicAutocompletion: true,
           enableLiveAutocompletion: false,
           enableSnippets: false,
           useWorker: false,
           showLineNumbers: true,
           tabSize: 2,
       }}/>
   }



   const products:{
    productOptimized: ProcessedProductDetails,
    productClient?: ProcessedProductDetails
   }[] = [];

   processedProducts = _.orderBy(processedProducts, ["indexOptimized"], ["asc"]);
   for (let i = 0; i < processedProducts.length; i++) {
       products.push({
        productOptimized: processedProducts[i],
        productClient: processedProducts.find(p => p.indexClient == i),
       })
   }

   return (<Table striped bordered hover className={' '}>
                <thead>
                 <tr>
                        <th className=''></th>
                        <th className='initial text-center' colSpan={2}>{`${t("Original")} (${pathDetailsClient.processedProductIds.length})`}</th>
                        <th className='optimized text-center' colSpan={2}>{`${t("Optimisé")} (${pathDetailsOptimized?.processedProductIds.length})` }</th>
                    </tr>
                    <tr>
                        <th className=''></th>
                        <th className='initial'>{t("adresse")}</th>
                        <th className='initial'>{t("quantité")}</th>

                        <th className='optimized'>{t("adresse")}</th>
                        <th className='optimized'>{t("quantité")}</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, i) => {


                        const addressClient = product.productClient?.address;
                        const quantityClient = product.productClient?.quantity;

                        const quantityOptimized = product.productOptimized?.quantity;
                        const addressOptimized = product.productOptimized?.address;

                        return <OverlayTrigger
                            key={i}
                            placement={"right"}
                            overlay={
                                <Tooltip style={{maxWidth: "100%"}}>
                                        {renderJsonEditor(product?.productClient?.raw, product?.productOptimized?.raw)}
                                </Tooltip>
                            }>
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td title={addressClient}>{addressClient}</td>
                                    <td className='initial'>{quantityClient}</td>
                                    <td title={addressOptimized}>{addressOptimized}</td>
                                    <td className='optimized'>{quantityOptimized}</td>
                                </tr>
                    </OverlayTrigger>                    
                    })}
                </tbody>
                <tfoot>
                {<tr>
                        <th style={{fontWeight: "normal"}}className=''><FontAwesomeIcon icon={["fas", "flag-checkered"]} /> </th>
                        <th style={{fontWeight: "normal"}}className='text-center initial' colSpan={2}>{pathDetailsClient.distance.toLocaleString("fr-FR")} {t("mètres")}</th>
                        <th style={{fontWeight: "normal"}}className='text-center optimized' colSpan={2}>{pathDetailsOptimized?.distance.toLocaleString("fr-FR") ?? "N/C"} {t("mètres")}</th>
                </tr>}
                {/*gainPourcentString && gainPourcentString!="NaN" && <tr>
                        <th className=''><FontAwesomeIcon icon={["fas", "percent"]} /> </th>
                        <th className='text-center' colSpan={4}>{parseFloat(gainPourcentString)}%</th>
                </tr>*/}
                </tfoot>
                </Table>)
}
export default MiddlewareBatchTable
import i18next from "i18next";
import { toast } from "react-toastify";
import {middlewarePickingActions} from '../middlewarePickingSlice';
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";
import dayjs from "dayjs";



export const loadRawDataThrunk = (
        baseUrl: string, 
        mapId: string, 
        mission: string,
        from: string, 
        to: string,
        page: number,
        pageSize = 10): AppThunk => {
    return async (dispatch, getState) => {
      try {
        
        console.log("AAAA", from, to)
        const tenant = getState().userProfil.currentTenant?.tenant
        dispatch(middlewarePickingActions.setLoadingList(true));

        const fromDate = dayjs(from).toDate();
        const toDate = dayjs(to).toDate();
        //remove timeoffset
        const fromParam = dayjs(from).add(-fromDate.getTimezoneOffset() ,"minutes").toISOString();
        const toParam = dayjs(to).add(-toDate.getTimezoneOffset() ,"minutes").add(1, "day").toISOString();

        const rawProducts = await apiMiddlewareDefinition(baseUrl, tenant ?? "")
                                  .requests.searchRequestProducts(mapId, {
                                    mapId: mapId,
                                    mission: mission,
                                    from: fromParam,
                                    to: toParam,
                                    pageSize: pageSize,
                                    page: page
                                  });

       dispatch(middlewarePickingActions.setRequestProducts(rawProducts.data));

      } catch (error: any) {

        if (!error.status || error.status != 401) {
          toast.error(i18next.t("Erreur lors du chargement des produits client"));
          console.error(error)
          //dispatch(middlewarePickingActions.setRawProducts(undefined));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(middlewarePickingActions.setLoadingList(false));
      }
    };
  };
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import "./MenuAdmin.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';
import { ROUTES } from 'resources/routes-constants';
import { userProfilActions } from 'store/reducers/Profil/userProfilSlice';
import _ from 'lodash';
import GlobalSearchForm from './forms/GlobalSearchForm';
import { ClientItem, MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import TenantSelector from 'components/selectors/TenantSelector';
import { selectTenantForClientThunk } from 'store/reducers/Profil/thrunks/selectTenantForClientThunk';
import MapSelector from 'components/selectors/MapSelector';

const MenuAdmin: React.FC = () => {
    const { t } = useTranslation(nameOf({MenuAdmin}), { useSuspense: false});
    const { 
        currentUser, 
        userInfos, 
        currentTenant 
    } = useAppSelector(state => state.userProfil);


    const allMaps = useAppSelector(state => state.adminClientMap.maps);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, mapId } = useParams();
    const currentMapSelected = mapId ? allMaps.find(m => m.id.toString() == mapId) : undefined;

    if(!currentUser) return null;

    let initial = "";
    let fullName = currentUser.email;

    if(userInfos && userInfos.firstName){
        initial += userInfos.firstName[0];
        fullName = userInfos.firstName;
    }
    if(userInfos && userInfos.lastName){
        initial +=  userInfos.lastName[0];
        fullName += (" " + userInfos.lastName);
    }
    
    const accountName = <Row id="account-name" className='m-3 align-items-center'>
        <Col className='px-0' xs="auto"><span className="user-photo" >{initial}</span></Col>
        <Col className='text-truncate'>
            <div><span className="user-name lato">{fullName}</span></div>
            <div><Link to={ROUTES.DASHBOARD} className='admin-btn'>Mode client</Link></div>
        </Col>
        <Col className='px-0' xs="auto"><button type="button" className='button-transparent' onClick={() => dispatch(userProfilActions.setLogout())}><span className="log-out" /></button></Col>
    </Row>


    const renderMenuItem = (label: string, to: string, iconName: IconName | undefined = undefined, iconPrefix: IconPrefix = "fas", subLevel = 0, className: string | undefined = undefined) => {

        const cssClass = "level-" + subLevel;

        return  <div className='menu-item'>
                <NavLink
                    className={({ isActive }) => cssClass  + " " + (isActive ? "selected" : undefined) + " " + className}
                    to={to}
                    style={({ isActive }) => isActive ? {} : undefined}>
                        <Row>
                            {subLevel > 0 && <Col sm={subLevel}></Col>}
                            {iconName && <Col sm="1"><FontAwesomeIcon icon={[iconPrefix, iconName]} size="lg"/></Col>}
                            <Col className='ms-2'>{label}</Col>
                        </Row>
                    
                </NavLink>
            </div>;
    }

    const onMapChange = (map: MapDetails) => {
        if(mapId)
        {
            // replace old map id in current url with new map id selected
            navigate(location.pathname.replaceAll(mapId, map.id), { replace: true });
        }
    }

    const onCurrentTenantChanged = (tenant: ClientItem) => {
        //Change client id in url
        const newPath = location.pathname.replace(`client/${currentTenant?.id}/`, `client/${tenant.id}/`)
        // select new client in the store
        dispatch(selectTenantForClientThunk(tenant));
        navigate(newPath);
    }
    
    const isUserDetailsPage = location.pathname.startsWith(ROUTES.ADMIN.USER().BASE_PATH + "/") && location.pathname != ROUTES.ADMIN.USER().CREATE
    const isClientSelected = !!currentTenant;
    const isMapSelected = !!currentMapSelected;
    const is3dSection = location.pathname.includes("/3d/");
    const isIntermarcheClient = isClientSelected && currentTenant.tenant == "intermarche";
    const isChronodriveClient = isClientSelected && currentTenant.tenant == "chronodrive";

    return (
        <div id="menu-admin">
            <div>{accountName}</div>
            <div><GlobalSearchForm /></div>
            <div className='menu-items mt-4'>
                {!isClientSelected && <>

                    {renderMenuItem(t('Tableau de bord'), ROUTES.ADMIN.DASHBOARD, 'chart-line')}
                    {renderMenuItem(t('Clients'), ROUTES.ADMIN.CLIENTS, 'database')}
                    {location.pathname == ROUTES.ADMIN.CLIENT_CREATE && renderMenuItem(t('Nouveau'), ROUTES.ADMIN.CLIENT_CREATE, 'plus', undefined, 1)}

                    {renderMenuItem(t('Utilisateurs'), ROUTES.ADMIN.USERS, 'users')}
                    {location.pathname == ROUTES.ADMIN.USER().CREATE && renderMenuItem(t('Nouveau'), ROUTES.ADMIN.USER().CREATE, 'plus', undefined, 1)}
                    {isUserDetailsPage && renderMenuItem(t('Détails'), ROUTES.ADMIN.USER(userId).DETAILS, 'edit', undefined, 1)}

                    {renderMenuItem(t('Traductions backoffice'), ROUTES.ADMIN.TRANSLATIONS, 'globe')}

                    {renderMenuItem(t('Picking'), ROUTES.ADMIN.PICKING.INDEX, 'box')}
                    {location.pathname.startsWith("/admin/picking/") && renderMenuItem(t('Middlewares'), ROUTES.ADMIN.PICKING.MIDDLEWARES, 'server', undefined,1)}
                    {location.pathname.startsWith("/admin/picking/") && renderMenuItem(t('Runners'), ROUTES.ADMIN.PICKING.RUNNERS, 'boxes-packing', "fas", 1)}

                    {renderMenuItem(t('Temps réel'), ROUTES.ADMIN.REALTIME.INDEX, 'bolt')}
                    {location.pathname.startsWith("/admin/realtime") && renderMenuItem(t('Changements carte'), ROUTES.ADMIN.REALTIME.MAP_CHANGES, 'cubes', undefined, 1)}
                    {location.pathname.startsWith("/admin/realtime") && renderMenuItem(t('Utilisateurs connectés'), ROUTES.ADMIN.REALTIME.CONNECTED_USERS, 'network-wired', undefined, 1)}
                    
                </>}

                {isClientSelected && <>
                    <TenantSelector 
                        tenants={currentUser.tenants} 
                        currentTenant={currentTenant} 
                        onTenantChange={onCurrentTenantChanged}/>
                    {!isMapSelected && <>
                    
                        {renderMenuItem(t('Lieux'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAPS, 'location-dot', "fas")}
                        {location.pathname == ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_CREATE && renderMenuItem(t('Nouveau'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_CREATE, 'plus', undefined, 1)}
                        {renderMenuItem(t('Détails'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).DETAILS, 'edit', "fas")}
                        {renderMenuItem(t('Permissions'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).PERMISSIONS, 'key', "fas")}
                        {renderMenuItem(t('Clés API'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).API_TOKENS, 'link', "fas")}
                        {renderMenuItem(t('Picking requêtes'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).PICKING_REQUESTS, 'cubes-stacked', "fas")}
                        {/*renderMenuItem(t('Audits'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).AUDITS, 'table', "fas")*/}
                        {isIntermarcheClient && <>
                            {renderMenuItem('Test middleware', ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).TEST_MIDDLEWARE, 'play',"fas")}
                        </>}
                        {isChronodriveClient && <>
                            {renderMenuItem('Test middleware', ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).CHRONODRIVE_MIDDLEWARE_TEST, 'play',"fas")}
                        </>}
                        {renderMenuItem(t('Storage'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).FILE_MANAGER, 'folder-open', "fas")}
                        {renderMenuItem(t('Retour'), ROUTES.ADMIN.CLIENTS, 'chevron-left', "fas", undefined, "back-btn")}
                    </>}

                    {isMapSelected && <>
                        <MapSelector 
                            allMaps={allMaps} 
                            currentMap={currentMapSelected} 
                            onMapChange={onMapChange}/>
                        {renderMenuItem(t("Détails"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_DETAILS(currentMapSelected.id), 'edit', undefined)}
                        {/* Désactivé car unity gère mal le destroy scene pour le moment quand on change de map*/}
                        {/*renderMenuItem(t("Viewer 3D"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_VIEWER_3D(currentMapSelected.id), 'cubes', undefined)*/}

                        
                        {renderMenuItem(t("Unity 3D"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ASSETS(currentMapSelected.id), 'cube', undefined)}
                        {is3dSection && <>
                            {renderMenuItem(t("Assets")  + ` (${currentMapSelected.totalAssets})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ASSETS(currentMapSelected.id), 'cube', undefined, 1)}
                            {renderMenuItem(t("Asset templates"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ASSET_TEMPLATE(currentMapSelected.id), 'tag', undefined, 1)}
                            {renderMenuItem(t("Polygones")  + ` (${currentMapSelected.totalPolygons})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_POLYGONS(currentMapSelected.id), 'draw-polygon', undefined, 1)}
                            {renderMenuItem(t("Polygone Templates") + ` (${currentMapSelected.totalTemplates})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_TEMPLATES(currentMapSelected.id), 'tags', undefined, 1)}
                            {renderMenuItem(t("Bâtiments")  + ` (${currentMapSelected.totalBuildings})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_BUILDINGS(currentMapSelected.id), 'building', undefined, 1)}
                            {renderMenuItem(t("Etages")  + ` (${currentMapSelected.totalFloors})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_FLOORS(currentMapSelected.id), 'stairs', undefined, 1)}
                            {renderMenuItem(t("Offmeshlinks") + ` (${currentMapSelected.totalOffMeshLinks})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_OFFMESHLINKS(currentMapSelected.id), 'link', undefined, 1)}
                            {renderMenuItem(t("Markers") + ` (${currentMapSelected.totalMarkers})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_MARKERS(currentMapSelected.id), 'location-dot', undefined, 1)}
                            {renderMenuItem(t("Labels") + ` (${currentMapSelected.totalLabels})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_LABELS(currentMapSelected.id), 'tag', undefined, 1)}
                            {renderMenuItem(t("Label templates"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_LABEL_TEMPLATES(currentMapSelected.id), 'tags', undefined, 1)}
                            {renderMenuItem(t("Marker templates") + ` (${currentMapSelected.totalMarkerTemplates})`,  ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_MARKER_TEMPLATES(currentMapSelected.id), 'bookmark', undefined, 1)}
                            {renderMenuItem(t("Navmeshes") + ` (${currentMapSelected.totalNavmeshes})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_NAVMESH(currentMapSelected.id), 'layer-group', undefined, 1)}
                            {renderMenuItem(t("Zones") + ` (${currentMapSelected.totalZones})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ZONE(currentMapSelected.id), 'vector-square', undefined, 1)}
                            {renderMenuItem(t("Metadata") + ` (${currentMapSelected.totalMetadataGroups})`, ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_METADATA_GROUPS(currentMapSelected.id), 'table', undefined, 1)}
                            {renderMenuItem(t("Configurations"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_SETTING(currentMapSelected.id), 'gears', undefined, 1)}
                        </>}

                        
                        {renderMenuItem(t("Picking setting"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_PICKING_SETTING(currentMapSelected.id), 'boxes-packing', undefined)}
                        {renderMenuItem(t("Audits"), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_AUDITS(currentMapSelected.id), 'table', undefined)}
                        {renderMenuItem(t('Retour'), ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAPS, 'chevron-left', "fas", undefined, "back-btn")}
                    </>}
                    
                </>}
                


                
            </div>
        </div>
    )
}

export default MenuAdmin

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import "./MiddlewareRequestList.scss"
import { loadMiddlewareRequestsThunk } from 'store/reducers/Admin/MiddlewareRequests/thrunks/loadMiddlewareRequestsThunk';
import { Badge, Button, ButtonGroup, Col, Container, Dropdown, Form, Offcanvas, Row } from 'react-bootstrap';
import { adminMiddlewareRequestActions } from 'store/reducers/Admin/MiddlewareRequests/middlewareRequestSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { RequestClientDetails } from 'backend/ApiMiddlewareDefinition/data-contracts';
import { SortOrder, TableColumn } from 'react-data-table-component';
import dayjs from 'dayjs';
import MiddlewareRequestDetails from 'components/Admin/MiddlewareRequest/MiddlewareRequestDetails';
import computeGainPourcent from 'utility/computeGainPourcent';
import Switch from "react-switch";
import MiddlewareAdminSelector from 'components/Admin/Middleware/MiddlewareAdminSelector';
import { ClientItem, MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { MiddlewareApiDetails } from 'backend/ApiAdminDefinition/data-contracts';
import MapSelector from 'components/selectors/MapSelector';
import ButtonFno from 'components/inputs/ButtonFno';


const MiddlewareRequestList: React.FC = () => {
    const { t } = useTranslation(nameOf({MiddlewareRequestList}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const selectedAppClient = useAppSelector(state => state.userProfil.currentTenant);
    const selectedClientMaps = useAppSelector(state => state.adminClientMap.maps);
    const allClients = useAppSelector(state => state.adminClient.allClients);
    const {
        loadingForm,
        loadingList,
        selectedMiddleware,
        selectedMap,
        selectedRequest,
        requests
    } = useAppSelector(state => state.adminMiddlewareRequest);
    const allMiddlewares = useAppSelector(state => state.adminRealtimeMiddleware.middlewares.filter(m => m.clientTenant == selectedAppClient?.tenant));
    const { clientId } = useParams();
    
    // Save old client selected for manage reset after client change
    const [clientSelected, setClientSelected] = useState<ClientItem | undefined>(selectedAppClient);

    const [onlyError, setOnlyError] = useState(false);
    const [onlyClientBetter, setOnlyClientBetter] = useState(false);
    const [onlyLongDuration, setOnlyLongDuration] = useState(false);

    useEffect(() => {
        document.title = t("[Admin] Les requêtes d'optimisation");
    }, [])



    useEffect(() => {
        setClientSelected(selectedAppClient);

        if(selectedAppClient != clientSelected){
            // reset requests list
            dispatch(adminMiddlewareRequestActions.setRequests(undefined))
            dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(undefined))
            dispatch(adminMiddlewareRequestActions.setSelectedMap(undefined))
        }

    }, [selectedAppClient])

    useEffect(() => {
        if(allMiddlewares.length > 0 && !selectedMiddleware)
        {
            
            dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(allMiddlewares[0]));
        }

        if(allMiddlewares.length == 0 && selectedMiddleware)
        {
            dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(undefined));
        }
    }, [allMiddlewares])



    useEffect(() => {
        if(selectedMiddleware)
        {
            searchrequest({})
        }
    }, [selectedMiddleware])

    useEffect(() => {
        searchrequest({})
    }, [selectedMap])


    useEffect(() => {
        searchrequest({})
    }, [onlyError, onlyClientBetter, onlyLongDuration])

    const searchrequest = (params :{
        sortColumn?: string ;
        sortDirection?: string;
        newPerPage?: number;
        page?: number;
    }) => {
        if(selectedAppClient && selectedMiddleware)
        {
            dispatch(loadMiddlewareRequestsThunk(
                selectedMiddleware.baseUrl, 
                selectedAppClient.tenant, 
                selectedMap?.id,
                params.sortColumn ?? requests?.sortColumn ?? "DateCreated",
                params.sortDirection ?? requests?.sortDirection ?? "desc",
                params.page ?? requests?.currentPage ?? 1,
                params.newPerPage ?? requests?.pageSize ?? 10,
                onlyLongDuration ? "0.00:00:02": "",
                onlyClientBetter,
                onlyError
                ));
        }
    }

    const refreshSearch = () => {
        searchrequest({
            sortColumn: requests?.sortColumn ?? "DateCreated",
            sortDirection: requests?.sortDirection ?? "desc",
            page: requests?.currentPage ?? 1,
            newPerPage: requests?.pageSize ?? 10,
        })
    }


    const selectRequest = (request: RequestClientDetails | undefined) => {
        dispatch(adminMiddlewareRequestActions.setSelectedRequest(request));
    }

    if(!selectedAppClient)
    {
        return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
    }
 
    const columns: TableColumn<RequestClientDetails>[] = [
        /*{
            id:'missions',
            name: t('Missions'),
            selector: row => row.mapId,
            cell: row => row.
        },*/
        {
            id:'mapName',
            name: t('Map'),
            selector: row => row.mapId,
            cell: row => selectedClientMaps.find(m => m.id == row.mapId)?.name ?? row.mapId
        },
        {
            id:'status',
            name: t('Status'),
            selector: row => row.status,
            cell: row => <>
                {row.status == "Pending" && <Badge bg="primary">{row.status}</Badge>}
                {row.status == "Completed" && <Badge bg="success">{row.status}</Badge>}
                {row.status == "Error"&& <Badge bg="danger">{row.status}</Badge>}
            </>
        },
        {
            id:'picker',
            name: t('Picker'),
            selector: row => row.pickerRefs?.join(", "),
            cell: row => <small>{row.pickerRefs?.join(", ")}</small>
        },
        {
            id:'totalProducts',
            name: t('Nb produits'),
            selector: row => row.totalProducts,
        },
        {
            id:'totalMissions',
            name: t('Nb missions'),
            selector: row => row.totalMissions,
        },
        {
            id:'gain',
            name: t('Gain (%)'),
            selector: row => row.percentGain,
            cell: row => {
                const gain = row.percentGain;
                let badgeColor = "warning";
                if(gain > 0) badgeColor = "success";
                else if(gain < 0) badgeColor = "danger";

                return <>
                    <span className='me-2'>{`${row.totalPathGain?.toFixed(2) ?? "N/C"} m`}</span>
                    <Badge bg={badgeColor}>{row.percentGain?.toFixed(2) ?? "N/C"}%</Badge>
                </>
            }
        },
        {
            id:'duration',
            name: t('Durée'),
            selector: row => row.durationMs,
            cell: row => {

                if(row.durationMs < 1000)
                {
                    return <Badge bg="success">{Math.trunc(row.durationMs)/ 1000} s</Badge>
                }
                else if(row.durationMs < 2000)
                {
                    return <Badge bg="warning">{Math.trunc(row.durationMs)/ 1000} s</Badge>
                }
                else
                {
                    return <Badge bg="danger">{Math.trunc(row.durationMs)/ 1000} s</Badge>
                }
            },
        },
        {
            id:'dateCreated',
            name: t('Date'),
            sortable: true,
            selector: row => row.dateCreated,
            cell: row => dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm")
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div>
                    <Button variant="success" size='sm' onClick={() => selectRequest(row)}>{t("Details")} <FontAwesomeIcon icon={["fas", "circle-info"]}/></Button>
            </div>
            }
        }
    ];





    const onSort = (selectedColumn: TableColumn<RequestClientDetails>, sortDirection: SortOrder) => {
        searchrequest({
            sortColumn: selectedColumn.id?.toString(),
            sortDirection: sortDirection
        })
    }

    const onChangeRowsPerPage = (newPerPage: number, page: number) => {
        searchrequest({
            newPerPage: newPerPage,
            page: page
        })
    }

    const onChangePage = (page: number) => {
        searchrequest({
            page: page
        })
    }

    const onMapChange = (map: MapDetails) => {
        dispatch(adminMiddlewareRequestActions.setSelectedMap(map))
    }

    const onMiddlewareChange = (middleware: MiddlewareApiDetails) => {
        dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(middleware))
    }

    return (
        <div id="admin-client-middleware-request">
             <Container id="search-panel" fluid>
                <Row>
                    <Col>
                        <MiddlewareAdminSelector onMiddlewareChange={onMiddlewareChange} middlewareIdSelected={selectedMiddleware?.id}/>
                    </Col>
                    <Col>
                        <MapSelector 
                            allMaps={selectedClientMaps} 
                            currentMap={selectedMap} 
                            isClearable
                            onMapChange={onMapChange}/>
                    </Col>
                </Row>
             </Container>
             
             <TableWithCrudComponent 
            entities={requests?.items ?? []} 
            columns={columns}
            loadingForm={loadingForm}
            loadingList={loadingList}
            addDefaultActionsColumn={false}
            translations={{
                tableTitle: t("Requêtes"),
                noEntityText: t("Aucune requête"),
                createTitle:  "",
                createButtonText: "",
                deleteText: (entity) => "",
                deleteTitle: (entity) =>  "",
                updateText: (entity) => "",
            }}

            initialValueCreateForm={{}}
            defaultSortFieldId={"dateCreated"}
            serverSide={true}
            onSort={onSort}
            customHeader={<>
                <div className='d-flex align-items-center me-5'> 
                    <Form.Label className='m-0 me-1'>{t("Requêtes > 2sec")}</Form.Label>
                    <Switch checked={onlyLongDuration} onChange={(enabled) => setOnlyLongDuration(enabled)}/>
                </div>

                <div className='d-flex align-items-center me-5'> 
                    <Form.Label className='m-0 me-1'>{t("Requêtes en erreur")}</Form.Label>
                    <Switch checked={onlyError} onChange={(enabled) => setOnlyError(enabled)}/>
                </div>

                <div className='d-flex align-items-center me-2'> 
                    <Form.Label className='m-0 me-1'>{t("Client meilleur optimization")}</Form.Label>
                    <Switch checked={onlyClientBetter} onChange={(enabled) => setOnlyClientBetter(enabled)}/>
                </div>
                <div className='d-flex align-items-center me-2'> 
                    <ButtonFno color='blue' onClick={() => refreshSearch()}><FontAwesomeIcon icon={["fas", "refresh"]}/></ButtonFno>
                </div>
            </>}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationTotalRows={requests?.totalCount ?? 0}
            paginationPerPage={requests?.pageSize ?? 0}
            />

        <Offcanvas className="offcanvas-request-details" show={!!selectedRequest} onHide={() => selectRequest(undefined)} >
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>{selectedRequest?.id}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {selectedRequest && <MiddlewareRequestDetails request={selectedRequest} />}
            </Offcanvas.Body>
      </Offcanvas>


        </div>
    )
}

export default MiddlewareRequestList

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    OptimizationBatchDetails,
    PagedListResultPathDetailsModel,
    PagedListResultRequestClientDetails,
    PagedListResultRequestProductsDetails,
    PathDetailsBatchField,
    PathDetailsModel,
    RealtimeReconnectResult,
    RequestClientDetails,
    RequestJsonsDetails,
    RequestLogsDetails,
    RequestProductsDetails
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class OptimizationResult<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name RealtimeReconnect
     * @request GET:/realtime/connect
     * @secure
     */
    realtimeReconnect = (params: RequestParams = {}) =>
        this.request<RealtimeReconnectResult, any>({
            path: `/realtime/connect`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name SearchRequests
     * @request GET:/requests
     * @secure
     */
    searchRequests = (
        query: {
            mapId?: string
            /**
             * Nullable<TimeSpan>
             * @format date-span
             */
            durationMin?: string
            /** Nullable<Boolean> */
            onlyError?: boolean
            /** Nullable<Boolean> */
            clientIsBetter?: boolean
            /** @format int32 */
            page: number
            /** @format int32 */
            pageSize: number
            sortColumn?: string
            sortDirection?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<PagedListResultRequestClientDetails, any>({
            path: `/requests`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name SearchPathsDetails
     * @request GET:/paths
     * @secure
     */
    searchPathsDetails = (
        query: {
            mapId?: string
            type?: PathDetailsBatchField
            /** @format date-time */
            from?: string
            /** @format date-time */
            to?: string
            mission?: string
            /** @format int32 */
            page: number
            /** @format int32 */
            pageSize: number
            sortColumn?: string
            sortDirection?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<PagedListResultPathDetailsModel, any>({
            path: `/paths`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestDetails
     * @request GET:/map/{mapId}/requests/{requestId}
     * @secure
     */
    getRequestDetails = (mapId: string, requestId: string, params: RequestParams = {}) =>
        this.request<RequestClientDetails, any>({
            path: `/map/${mapId}/requests/${requestId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestBatchs
     * @request GET:/map/{mapId}/requests/{requestId}/batchs
     * @secure
     */
    getRequestBatchs = (mapId: string, requestId: string, params: RequestParams = {}) =>
        this.request<OptimizationBatchDetails[], any>({
            path: `/map/${mapId}/requests/${requestId}/batchs`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestBatchDetails
     * @request GET:/map/{mapId}/requests/batchs/{batchId}
     * @secure
     */
    getRequestBatchDetails = (mapId: string, batchId: string, params: RequestParams = {}) =>
        this.request<OptimizationBatchDetails, any>({
            path: `/map/${mapId}/requests/batchs/${batchId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestBatchPathsOptimized
     * @request GET:/map/{mapId}/requests/batchs/{batchId}/paths/optimized
     * @secure
     */
    getRequestBatchPathsOptimized = (mapId: string, batchId: string, params: RequestParams = {}) =>
        this.request<PathDetailsModel[], any>({
            path: `/map/${mapId}/requests/batchs/${batchId}/paths/optimized`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestBatchPathsOriginal
     * @request GET:/map/{mapId}/requests/batchs/{batchId}/paths/original
     * @secure
     */
    getRequestBatchPathsOriginal = (mapId: string, batchId: string, params: RequestParams = {}) =>
        this.request<PathDetailsModel[], any>({
            path: `/map/${mapId}/requests/batchs/${batchId}/paths/original`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestJsons
     * @request GET:/map/{mapId}/requests/{requestId}/jsons
     * @secure
     */
    getRequestJsons = (mapId: string, requestId: string, params: RequestParams = {}) =>
        this.request<RequestJsonsDetails, any>({
            path: `/map/${mapId}/requests/${requestId}/jsons`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestLogs
     * @request GET:/map/{mapId}/requests/{requestId}/logs
     * @secure
     */
    getRequestLogs = (mapId: string, requestId: string, params: RequestParams = {}) =>
        this.request<RequestLogsDetails, any>({
            path: `/map/${mapId}/requests/${requestId}/logs`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name GetRequestProducts
     * @request GET:/map/{mapId}/requests/{requestId}/products
     * @secure
     */
    getRequestProducts = (mapId: string, requestId: string, params: RequestParams = {}) =>
        this.request<RequestProductsDetails, any>({
            path: `/map/${mapId}/requests/${requestId}/products`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags OptimizationResult
     * @name SearchRequestProducts
     * @request GET:/map/{mapId}/requests/products
     * @secure
     */
    searchRequestProducts = (
        mapId: string,
        query: {
            mapId?: string
            /** @format date-time */
            from?: string
            /** @format date-time */
            to?: string
            mission?: string
            /** @format int32 */
            page: number
            /** @format int32 */
            pageSize: number
            sortColumn?: string
            sortDirection?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<PagedListResultRequestProductsDetails, any>({
            path: `/map/${mapId}/requests/products`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
}

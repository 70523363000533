import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { userProfilActions } from 'store/reducers/Profil/userProfilSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import "./MenuClient.scss"
import MapSelector from './selectors/MapSelector';
import TenantSelector from './selectors/TenantSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';
import { ROUTES } from 'resources/routes-constants';
import Loading from './Loading';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import AuditSelector from './Audit/AuditSelector';
import MiddlewareSelector from './selectors/MiddlewareSelector';
import StatisticDatesForm from 'pages/Client/Middleware/Picking/StatisticDatesForm';
import checkPermissions from 'utility/checkPermissions';
import { AuditRawFileDetails, ClientItem, MapDetails, PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';
import { selectTenantForClientThunk } from 'store/reducers/Profil/thrunks/selectTenantForClientThunk';
import { selectCurrentMapThrunks } from 'store/reducers/Map/thrunks/selectCurrentMapThrunks';
import { SelectAuditDataThunk } from 'store/reducers/Audit/thrunks/SelectAuditDataThunk';

/**
 * The MenuClient component is the main menu of the application for the client user.
 * It displays the current user's account name, tenant and map selector, module selector (if enabled),
 * and provides access to different features of the application based on the user's permissions.
 * It also handles the current tenant and map selection.
 *
 * @return {JSX.Element} The MenuClient component.
 */
const MenuClient: React.FC = () => {
    const { t } = useTranslation(nameOf({Menu: MenuClient}), { useSuspense: false});

    const {
        currentUser,
        currentTenant,
        userInfos,
        loadingUserData
      } = useAppSelector(state => state.userProfil);

      const {
        auditRawFiles, 
        auditRawFileSelected
    } = useAppSelector(state => state.audit);


    const moduleMiddlewareEnabled = useAppSelector(state => state.middlewareApis.middlewareSelected != null);

    const {
        currentMap,
        maps
        } = useAppSelector(state => state.map);

    const locationRouter = useLocation();
    const dispatch = useAppDispatch();

    if(!currentUser) return null;

    const moduleAuditEnabled = checkPermissions(PermissionsEnum.AuditList); //(currentMap?.totalAudits ?? 0) > 0;
    const moduleBatchingEnabled = checkPermissions(PermissionsEnum.BatchingRawFileList);
    
    let initial = "";
    let fullName = currentUser.email;

    if(userInfos && userInfos.firstName){
        initial += userInfos.firstName[0];
        fullName = userInfos.firstName;
    }
    if(userInfos && userInfos.lastName){
        initial +=  userInfos.lastName[0];
        fullName += (" " + userInfos.lastName);
    }


    const accountName = <Row id="account-name" className='m-3 align-items-center'>
        <Col className='px-0' xs="auto"><span className="user-photo" >{initial}</span></Col>
        <Col className='text-truncate'>
            <div><span className="user-name lato">{fullName}</span></div>
            {currentUserIsAdmin() && <div><Link to={ROUTES.ADMIN.DASHBOARD} className='admin-btn'>Mode Admin</Link> </div>}
            </Col>
        <Col className='px-0' xs="auto"><button type="button" className='button-transparent' onClick={() => dispatch(userProfilActions.setLogout())}><span className="log-out" /></button></Col>
    </Row>;


    const renderMenuItem = (label: string, to: string, iconName: IconName, iconPrefix: IconPrefix = "fas", isSubPage = false) => {

        return  <div className='menu-item'>
                <NavLink
                    className={({ isActive }) => isActive ? "selected" : undefined}
                    to={to}
                    style={({ isActive }) => isActive ? {} : undefined}>
                        <Row>
                            {isSubPage && <Col sm="1"></Col>}
                            <Col sm="1"><FontAwesomeIcon icon={[iconPrefix, iconName]} size="lg"/></Col>
                            <Col className='ms-2'>{label}</Col>
                        </Row>
                    
                </NavLink>
            </div>;
    }

    const onCurrentTenantChanged = (tenant: ClientItem) => {
        dispatch(selectTenantForClientThunk(tenant));
    }

    const onCurrentMapChaned = (map: MapDetails) => {
        dispatch(selectCurrentMapThrunks(map));
        
        setTimeout(() => {
            location.reload();
        }, 200)
    }

    const onAuditChange = (auditFile: AuditRawFileDetails) => {
        dispatch(SelectAuditDataThunk(auditFile))
    }


    const isAuditPage = locationRouter.pathname.startsWith("/audit/")
    const isMiddlewarePickingPage = locationRouter.pathname.startsWith("/picking/")
    const isMiddlewareBatchingPage = locationRouter.pathname.startsWith("/batching/")

    const isTimcodeDemo = ["665f2938ae59a4ee44146edc", "665f19caae59a4ee44146e25"].includes(currentMap?.id ?? ""); // ! id map timcode sur preprod
    const isIvantiDemo = ["666ae43ed7504d9bf101b99e"].includes(currentMap?.id ?? ""); // Heatmap démo sur ivanti preprod
    
    console.log("AAAAAA", moduleMiddlewareEnabled)
    return (
        <div id="menu">
            
            <div>{accountName}</div>
            <div>
                <TenantSelector 
                    tenants={currentUser.tenants} 
                    currentTenant={currentTenant} 
                    onTenantChange={onCurrentTenantChanged}/>
            </div>
            <div>
                <MapSelector 
                    allMaps={maps} 
                    currentMap={currentMap} 
                    onMapChange={onCurrentMapChaned}/>
            </div>
            {moduleMiddlewareEnabled  && (isMiddlewarePickingPage || isMiddlewareBatchingPage) && <>
                <div><MiddlewareSelector /></div>
            </>}

            <div><StatisticDatesForm /></div>
            
            {moduleAuditEnabled && isAuditPage && 
                <div>
                    <AuditSelector 
                        audits={auditRawFiles}
                        auditSelected={auditRawFileSelected}
                        onAuditChange={onAuditChange}/>
                </div>}
            
            {!loadingUserData && <div className='menu-items mt-5'>
                {renderMenuItem(t('Tableau de bord'), ROUTES.DASHBOARD, 'chart-line')}
                {/*checkPermissions(PermissionsEnum.StatisticsRead) && renderMenuItem(t('Statistiques'), ROUTES.STATISTICS, 'chart-column')*/}
                {/*renderMenuItem(t('Statistiques'), ROUTES.STATISTICS, 'chart-column') */}
                
                {/*renderMenuItem(t('Requêtes'), ROUTES.PICKING_REQUESTS, 'map-location',"fas")*/}
                {/*renderMenuItem(t('Commandes'), ROUTES.PICKING_ORDERS, 'box',"fas")*/}
                {/*renderMenuItem(t('Commandes SAP'), ROUTES.SAP_ORDERS_LIST, 'box',"fas")*/}

                
                {moduleAuditEnabled && checkPermissions(PermissionsEnum.AuditList) && <>
                    {renderMenuItem(t('Audits'), ROUTES.AUDIT_DASHBOARD, 'bars',"fas")}
                    {isAuditPage && <>
                        {checkPermissions(PermissionsEnum.AuditRawData) && renderMenuItem(t('Données client'), ROUTES.AUDIT_RAW_DATA, 'table',"fas", true)}
                        {checkPermissions(PermissionsEnum.AuditResultPath) && renderMenuItem(t('Résultat itinéraire'), ROUTES.AUDIT_PATH_COMPARE, 'location-pin',"fas",true)}
                        {checkPermissions(PermissionsEnum.AuditResultBatching) && renderMenuItem(t('Résultat regroupement'), ROUTES.AUDIT_BATCHING, 'boxes-stacked',"fas",true)}
                        {checkPermissions(PermissionsEnum.AuditResultFinalPath) && renderMenuItem(t('Résultat regroupement chemin'), ROUTES.AUDIT_FINAL_PATH_CLIENT_RESULT, 'route',"fas",true)}
                        {checkPermissions(PermissionsEnum.AuditResultFinalBatching) && renderMenuItem(t('Résultat final'), ROUTES.AUDIT_FINAL_RESULT, 'clipboard-check',"fas",true)}
                        {checkPermissions(PermissionsEnum.AuditHeatmapRead) && renderMenuItem(t('Résultat heatmap'), ROUTES.AUDIT_HEATMAP_COMPARE, 'table-cells',"fas",true)}
                    </>}
                </>}

                
                {moduleMiddlewareEnabled &&<>

                    {checkPermissions(PermissionsEnum.PickingOptimizationMenu) && <>
                        {renderMenuItem(t('Picking'), ROUTES.PICKING_DASHBOARD, 'box-archive',"fas")}
                        {isMiddlewarePickingPage && <>
                            {renderMenuItem(t('Statistiques'), ROUTES.PICKING_STATISTICS,'chart-column',"fas" ,true)}
                            {renderMenuItem(t('Données client'), ROUTES.PICKING_RAW_DATA, 'table',"fas", true)}
                            {renderMenuItem(t('Résultat itinéraire'), ROUTES.PICKING_PATH_COMPARE, 'location-pin',"fas" ,true)}
                        </>}
                    </>}
                    

                    {checkPermissions(PermissionsEnum.BatchingMenu) && <>
                        {renderMenuItem(t('Batching'), ROUTES.BATCHING_DASHBOARD, 'boxes-stacked',"fas")}
                        {isMiddlewareBatchingPage && <>
                            {renderMenuItem(t('Statistiques'), ROUTES.BATCHING_STATISTICS,'chart-column',"fas" ,true)}
                        </>}
                    </>}
                </>}

                {isTimcodeDemo && <>
                    {renderMenuItem(t('Gestion étiquettes'), ROUTES.DEMO.TIMCODE.TAG_MANAGER, 'tags',"fas")}
                    {renderMenuItem(t('Résultat regroupement'), ROUTES.DEMO.TIMCODE.BATCHING_RESULT, 'boxes-stacked',"fas")}
                    {/*renderMenuItem(t('Résultat Super U'), ROUTES.DEMO.TIMCODE.BATCHING_SUPER_U, 'clipboard-check',"fas")*/}
                    {renderMenuItem(t('Résultat Timcod'), ROUTES.DEMO.TIMCODE.BATCHING_TIMCODE, 'clipboard-check',"fas")}
                    
                </>}

                {isIvantiDemo && <>
                    {renderMenuItem(t('Heatmap'), ROUTES.DEMO.IVANTI.HEATMAP, 'table-cells',"fas")}
                 </>}
                
                {/*checkPermissions(BackofficeFeatures.user_read) && renderMenuItem(t('Gestion des droits'), ROUTES.MANAGE_USERS, 'user', 'far')}
                {checkPermissions(BackofficeFeatures.token_read) && renderMenuItem(t('Clés API'), ROUTES.MANAGE_TOKENS, 'link')*/}
            </div>}
            {loadingUserData && <div className='mt-5 text-center'><Loading/></div>}
            
        </div>
    );
}

export default MenuClient

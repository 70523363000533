import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PolygonTemplateDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map Template store
export interface IAdminClientMapTemplateStore {
  templates: PolygonTemplateDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map Template store
const initialState: IAdminClientMapTemplateStore = {
  templates: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map Template store
const adminClientMapTemplateSlice = createSlice({
  name: "adminClientMapTemplateSlice",
  initialState,
  reducers: {
    // Set the Templates in the state
    setTemplates: (state, action: PayloadAction<PolygonTemplateDetails[]>) => {
      state.templates = action.payload;
    },
    // Update a specific Template in the state
    updateTemplate: (state, action: PayloadAction<PolygonTemplateDetails>) => {
      state.templates = state.templates.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new Template to the state
    addTemplate: (state, action: PayloadAction<PolygonTemplateDetails>) => {
      state.templates.push(action.payload);
    },
    // remove an Template
    deleteTemplate: (state, action: PayloadAction<string>) => {
      state.templates = state.templates.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingTemplateList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingTemplateForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.templates = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map Template slice
export const {
  actions: adminClientMapTemplateActions,
  reducer: adminClientMapTemplateReducer,
} = adminClientMapTemplateSlice;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiErrorsResponse {
    /** Dictionary<String> */
    errors?: Record<string, string[]>
}

export interface Article {
    ean?: string | null
    address?: string | null
    productFamily?: string | null
    /** @format double */
    quantityToPrepare?: number
    isPromotional?: boolean
    mission?: string | null
}

export interface BaseBatchingRawFileColumnsBinding {
    /** @format int32 */
    fieldSupport: number
    /** @format int32 */
    fieldEmplacement: number
}

export interface BatchingColumnBindingProperty {
    /** @minLength 1 */
    fieldName: string
    /** @minLength 1 */
    fieldLabel: string
}

export enum BatchingProcessStateEnum {
    None = 'None',
    Waiting = 'Waiting',
    Processing = 'Processing',
    Processed = 'Processed',
    Error = 'Error'
}

/** BatchingRawFileCreate<BaseBatchingRawFileColumnsBinding> */
export interface BatchingRawFileCreateBaseBatchingRawFileColumnsBinding {
    columnsBindingData: BaseBatchingRawFileColumnsBinding
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    author: string
    delimiter?: string | null
    /** @format int32 */
    totalRows: number
    /** @minLength 1 */
    mapId: string
}

export interface BatchingRawFileCreateResult {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** Dictionary<String> */
    columnsBindingData: Record<string, number>
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    storagePathDataCsv: string
    /** @minLength 1 */
    storagePathResultCsv: string
    storagePathErrorCsv?: string | null
    /** @minLength 1 */
    author: string
    /** @minLength 1 */
    delimiter: string
    /** @format int32 */
    totalRows: number
    /** @format int32 */
    totalRowErrors: number
    state?: BatchingProcessStateEnum
    /** List<RequestStageDetails> */
    logs: RequestStageDetails[]
    /** @format double */
    totalOriginalDistance?: number
    /** @format double */
    totalOptimizedDistance?: number
    /** @format double */
    totalDistanceDiff?: number
    /** @format int32 */
    totalSupport?: number
    /**
     * Nullable<TimeSpan>
     * @format date-span
     */
    totalDuration?: string | null
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @format double */
    durationMs: number
    /** @minLength 1 */
    temporaryUploadUrlDataCsv: string
}

export interface BatchingRawFileDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** Dictionary<String> */
    columnsBindingData: Record<string, number>
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    storagePathDataCsv: string
    /** @minLength 1 */
    storagePathResultCsv: string
    storagePathErrorCsv?: string | null
    /** @minLength 1 */
    author: string
    /** @minLength 1 */
    delimiter: string
    /** @format int32 */
    totalRows: number
    /** @format int32 */
    totalRowErrors: number
    state?: BatchingProcessStateEnum
    /** List<RequestStageDetails> */
    logs: RequestStageDetails[]
    /** @format double */
    totalOriginalDistance?: number
    /** @format double */
    totalOptimizedDistance?: number
    /** @format double */
    totalDistanceDiff?: number
    /** @format int32 */
    totalSupport?: number
    /**
     * Nullable<TimeSpan>
     * @format date-span
     */
    totalDuration?: string | null
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @format double */
    durationMs: number
}

export enum DownloadFileType {
    Original = 'Original',
    Final = 'Final',
    Error = 'Error'
}

export interface ErrorsReturn {
    /** List<String> */
    errors?: string[] | null
}

export interface HomeStatusResult {
    build: string | null
}

/** KeyValuePair<String> */
export interface KeyValuePairStringString {
    key?: string | null
    value?: string | null
}

export interface MiddlewareStatusResult {
    realtimeOnline?: boolean
    buildDate?: string | null
}

export interface OptimizationBatchDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    requestClientId: string
    /** @minLength 1 */
    optimizationType: string
    /** List<String> */
    optimizePathDetailsIds: string[]
    /** List<String> */
    finalResultPathDetailsIds: string[]
    /** List<String> */
    clientPathDetailsIds: string[]
    /**
     * Nullable<TimeSpan>
     * @format date-span
     */
    processDuration: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

/** PagedListResult<BatchingRawFileDetails> */
export interface PagedListResultBatchingRawFileDetails {
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    pageSize: number
    sortColumn?: string | null
    sortDirection?: string | null
    /** IEnumerable<BatchingRawFileDetails> */
    items: BatchingRawFileDetails[]
    /** @format int32 */
    totalCount: number
    /** @format int32 */
    totalPages: number
}

/** PagedListResult<PathDetailsModel> */
export interface PagedListResultPathDetailsModel {
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    pageSize: number
    sortColumn?: string | null
    sortDirection?: string | null
    /** IEnumerable<PathDetailsModel> */
    items: PathDetailsModel[]
    /** @format int32 */
    totalCount: number
    /** @format int32 */
    totalPages: number
}

/** PagedListResult<RequestClientDetails> */
export interface PagedListResultRequestClientDetails {
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    pageSize: number
    sortColumn?: string | null
    sortDirection?: string | null
    /** IEnumerable<RequestClientDetails> */
    items: RequestClientDetails[]
    /** @format int32 */
    totalCount: number
    /** @format int32 */
    totalPages: number
}

/** PagedListResult<RequestProductsDetails> */
export interface PagedListResultRequestProductsDetails {
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    pageSize: number
    sortColumn?: string | null
    sortDirection?: string | null
    /** IEnumerable<RequestProductsDetails> */
    items: RequestProductsDetails[]
    /** @format int32 */
    totalCount: number
    /** @format int32 */
    totalPages: number
}

export enum PathDetailsBatchField {
    OptimizePathDetails = 'OptimizePathDetails',
    FinalResult = 'FinalResult',
    ClientPathDetails = 'ClientPathDetails'
}

export interface PathDetailsModel {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    optimizationBatchId: string
    batchField: PathDetailsBatchField
    /** @format date-time */
    dateRequestClient: string
    /** @minLength 1 */
    mission: string
    /** List<String> */
    processedProductIds: string[]
    /** @format double */
    distance: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface ProcessedProductDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    address: string
    /** @format double */
    quantity: number
    /** @format double */
    weight: number
    /** @format int32 */
    indexClient: number
    /** @format int32 */
    indexOptimized: number
    /** @minLength 1 */
    raw: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    rawAsJson?: any
}

export interface RealtimeReconnectResult {
    connected?: boolean
}

export interface RequestClientDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /**
     * Nullable<TimeSpan>
     * @format date-span
     */
    duration?: string | null
    /** @format int32 */
    totalProducts: number
    /** @format int32 */
    totalMissions: number
    /** @format double */
    totalOriginalDistance: number
    /** @format double */
    totalOptimizedDistance: number
    /** @format double */
    totalPathGain: number
    /** @format double */
    percentGain: number
    /** @minLength 1 */
    status: string
    /** List<KeyValuePair`2> */
    headers: KeyValuePairStringString[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** List<String> */
    pickerRefs: string[]
    /** @format double */
    durationMs: number
}

export interface RequestClientModel {
    storeId?: string | null
    deviceId?: string | null
    sessionId?: string | null
    /** List<Article> */
    articles?: Article[] | null
    isOptimized?: boolean
    isError?: boolean
}

export interface RequestJsonsDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    jsonRequestClient: string
    /** @minLength 1 */
    jsonRequestResult: string
    /** List<String> */
    jsonRunnerRequest: string[]
    /** List<String> */
    jsonRunnerResult: string[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface RequestLogsDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** List<RequestStageDetails> */
    logs?: RequestStageDetails[] | null
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface RequestProductsDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    mission?: string | null
    /** List<ProcessedProductDetails> */
    products?: ProcessedProductDetails[] | null
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface RequestStageDetails {
    /** @format date-time */
    date: string
    /** @minLength 1 */
    stageType: string
    /** @minLength 1 */
    message: string
    /** @format double */
    duration: number
    isError: boolean
}

export interface StatisticsByDayBatching {
    id: string | null
    /** @format date-time */
    date: string
    mapId: string | null
    /** @format int32 */
    totalRequests?: number
    /** @format int32 */
    totalRequestErrors?: number
    /** @format int32 */
    totalRequestOptimized?: number
    /** @format int32 */
    totalProducts?: number
    /** @format int32 */
    totalSupports?: number
    /** @format double */
    totalOriginalDistance?: number
    /** @format double */
    totalOptimizedDistance?: number
}

export interface StatisticsByDayPicking {
    id?: string | null
    /** @format int32 */
    version?: number
    /** @format date-time */
    dateCreated?: string
    /** @format date-time */
    dateUpdated?: string
    internalRef: string | null
    /** @format date-time */
    date: string
    mapId: string | null
    /** @format int32 */
    totalRequests?: number
    /** @format int32 */
    totalRequestErrors?: number
    /** @format int32 */
    totalRequestOptimized?: number
    /** @format int32 */
    totalProducts?: number
    /** @format double */
    totalQuantity?: number
    /** @format int32 */
    totalMissions?: number
    /** @format double */
    totalOriginalDistance?: number
    /** @format double */
    totalOptimizedDistance?: number
    /** Dictionary<String> */
    pickerTotal?: Record<string, number>
    /** Dictionary<String> */
    unknowAddressTotal?: Record<string, number>
}

export interface StorageDownloadLinkResult {
    downloadUrl: string | null
}

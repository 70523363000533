import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { Accordion, Badge, Col, Row, Table } from 'react-bootstrap';
import Sdk from 'fno-sdk';
import { OptimizationBatchDetails, PathDetailsModel, ProcessedProductDetails, RequestClientDetails } from 'backend/ApiMiddlewareDefinition/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Loading from 'components/Loading';
import { useAppDispatch, useAppSelector } from 'store/store';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import apiMiddlewareDefinition from 'backend/apiMiddlewareDefinition';
import "./MiddlewarePathCompareRow.scss";
import dayjs from 'dayjs';
import PaginationRender from 'components/PaginationRender';
import appColors from 'resources/appColors';
import computeGainPourcent from 'utility/computeGainPourcent';
import { mapControlsActions } from 'store/reducers/mapControls/mapControls';
import { Tooltip } from 'react-tooltip'

interface IMissionData {
    batchId: string | undefined;
    batch: OptimizationBatchDetails | undefined;
    pathDetailsClient: PathDetailsModel;
    pathDetailsOptimized: PathDetailsModel | undefined; 
    processedProducts: ProcessedProductDetails[];
    requestDetails: RequestClientDetails | undefined;
    loading: boolean;
}

interface OwnProps {
    sdk: Sdk;
    pathDetailsClients: PathDetailsModel[];
    loading: boolean;
    onChangePage: (page: number) => void;
 }

const MiddlewarePathCompareRow: React.FC<OwnProps> = ({sdk, pathDetailsClients, loading, onChangePage}) => {
   const { t } = useTranslation(nameOf({MiddlewarePathCompareRow}), { useSuspense: false});
   const dispatch = useAppDispatch();

   const [showInitial, setShowInitial] = useState(true);
   const [showOptimzed, setShowOptimzed] = useState(true);
   
   const [rowData, setRowData] = useState<IMissionData>({ loading: false } as IMissionData);

    const currentMap = useAppSelector(state => state.map.currentMap);
    const {
        missions,
    } = useAppSelector(state => state.middlewarePicking);

    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const colorOriginal = appColors.orange;
    const colorOptimized = appColors.blue;

   useEffect(() => {

        if(!rowData.batchId)
        {
            setRowData({batchId: undefined} as IMissionData);
            clearItineraries();
        }
        else
        {
            loadMissionData(rowData.batchId)
        }
    }, [rowData.batchId])

    useEffect(() => {

        if(rowData.batchId && !rowData.loading){

            showItinerary();
        }

    }, [showInitial, showOptimzed, rowData])


    // TODO migrer dans un thrunk ?
    const loadMissionData = async (batchId: string) => {

        setRowData({...rowData, loading: true});
        const tenant = getCurrentTenant()?.tenant ?? "";
        const mapId = currentMap?.id ?? "";

        const selectedPathDetails = pathDetailsClients.find(m => m.optimizationBatchId ==  batchId);


        const batchTask = apiMiddlewareDefinition(middlewareSelected?.baseUrl ?? "", tenant).requests.getRequestBatchDetails(mapId, batchId);

        const pathDetailsOptimizedTask = apiMiddlewareDefinition(middlewareSelected?.baseUrl ?? "", tenant).requests.getRequestBatchPathsOptimized(mapId, batchId);
        const pathDetailsOriginalTask = apiMiddlewareDefinition(middlewareSelected?.baseUrl ?? "", tenant).requests.getRequestBatchPathsOriginal(mapId, batchId);
        

        const [batch, 
            pathDetailsOptimized,
            pathDetailsOriginal] = await Promise.all([batchTask, pathDetailsOptimizedTask, pathDetailsOriginalTask]);


            const requestProductTask = apiMiddlewareDefinition(middlewareSelected?.baseUrl ?? "", tenant).requests.getRequestProducts(mapId, batch.data.requestClientId);
            const requestDetailsTask = apiMiddlewareDefinition(middlewareSelected?.baseUrl ?? "", tenant).requests.getRequestDetails(mapId, batch.data.requestClientId);

        const [requestProduct, 
            requestDetails] = await Promise.all([requestProductTask, requestDetailsTask]);
            
        // check current row selected not change
        if(batch.data.id == batchId){
            setRowData({
                batchId: batch.data.id,
                batch: batch.data,
                pathDetailsClient: pathDetailsOriginal.data.find(m => m.mission === selectedPathDetails?.mission),
                pathDetailsOptimized: pathDetailsOptimized.data.find(m => m.mission === selectedPathDetails?.mission),
                processedProducts: requestProduct.data.products ?? [],
                requestDetails: requestDetails.data,
                loading: false
            } as IMissionData);
        }

    }


    const changePage = async (page: number) => {
            clearItineraries();
            onChangePage(page);
    }

   const highlightAssets = async (locationCodes: string[], enabled: boolean, color: string) => {
        await clearHighlightAssets();
        const assets = (await sdk.asset.getAssetDataByLocationCodes(locationCodes)).assets;
        sdk.asset.setHighlightAssetByIds(assets.map(m => m.id), enabled, color)
    }

    const clearHighlightAssets = async () => {
        await sdk.asset.clearHighlights();
    }


    const showItinerary = async () => {
        if(sdk.pathfinding){
            await clearItineraries();

            if(rowData.pathDetailsClient && showInitial) {
                const processedOriginal = rowData.pathDetailsClient.processedProductIds.map((processedClientProductId, i) =>  rowData.processedProducts.find((p) => p.id == processedClientProductId)).filter(m => m != undefined);
                const locationCodes = processedOriginal.map(m => m?.address ?? "");
                const path = await sdk?.pathfinding.setItineraryToAssetsByLocationCode(["start", ...locationCodes, "end"], colorOriginal);
                if(path && path.currentFloorId)
                {
                    dispatch(mapControlsActions.setCurrentFloorById(path.currentFloorId))
                }

                await highlightAssets(locationCodes, true, colorOriginal);
            }

            if(rowData.pathDetailsOptimized && showOptimzed) {
                const processedOptimized = rowData.pathDetailsOptimized?.processedProductIds.map((processedClientProductId, i) =>  rowData.processedProducts.find((p) => p.id == processedClientProductId)).filter(m => m != undefined) ?? [];
                const path = await sdk?.pathfinding.setItineraryToAssetsByLocationCode(["start", ...processedOptimized.map(m => m?.address ?? ""), "end"], colorOptimized);
                if(path && path.currentFloorId)
                    {
                        dispatch(mapControlsActions.setCurrentFloorById(path.currentFloorId))
                    }
            }
        }
            
    }

    const clearItineraries = async () => {
        if(sdk.pathfinding)
            await sdk?.pathfinding.clearItineraries();

        clearHighlightAssets();
    }
   const checkComponent = (enabled: boolean, onClick: () => void) => {
        if(enabled) return <span className='checkbox selected' onClick={onClick}><FontAwesomeIcon icon={["fas", "square-check"]} size="lg"/> </span>;
        else return <span className='checkbox' onClick={onClick}><FontAwesomeIcon icon={["fas", "square"]} size="lg"/></span>
    }

    const renderLocationCode = (product: ProcessedProductDetails) => {
        
        const locationCode = product?.address ? product?.address : "";
        const id = _.uniqueId();
        return <>
            {/*product.error ? <span data-tooltip-id={id} data-tooltip-content={t(product.error)}><FontAwesomeIcon icon={["fas", "triangle-exclamation"]} /> <Tooltip id={id} place='left' /></span>: ""*/}
            {locationCode ? locationCode : <FontAwesomeIcon icon={["fas", "slash"]} />}
        </>
    }

   
    const locationCodes = rowData.processedProducts?.map(m => m?.address ?? "") ?? [];


   return (<><Accordion className="RequestPathCompareRow" onSelect={(batchId) => setRowData({batchId: batchId?.toString()} as IMissionData)}>

        {pathDetailsClients &&_.map(pathDetailsClients, (pathDetailsClient) => {

            const missionRef = pathDetailsClient.mission;
            const requestStatus = rowData.requestDetails?.status  ?? "";
            const requestBadgeColor = requestStatus === "Completed" ? "success" : requestStatus === "Error" ? "danger" : "primary";

            return <Accordion.Item eventKey={pathDetailsClient.optimizationBatchId} key={pathDetailsClient.optimizationBatchId}>
                <Accordion.Header >
                        {missionRef}
                        <Badge className='ms-2' bg="primary">{pathDetailsClient.processedProductIds.length} {t("Products")}</Badge>
                        <Badge className='ms-2' bg="info">{dayjs(pathDetailsClient.dateRequestClient).format("DD/MM/YYYY HH:mm")}</Badge>
                        {/*<Badge className='ms-2' bg="orange"> {gainPourcentString + "%"} {t("Gain")}</Badge>*/}
                    </Accordion.Header>
                <Accordion.Body>

                    <Row className='text-center mb-3'>
                        <Col><FontAwesomeIcon icon={["fas", "tags"]} /> {missionRef}</Col>
                    </Row>
                    <Row className='text-center mb-3'>
                        <Col><Badge className='ms-2' bg="primary">{rowData.requestDetails?.pickerRefs?.join(", ")}</Badge></Col>
                        <Col> <Badge className='ms-2' bg={requestBadgeColor}>{t(requestStatus)}</Badge></Col>
                        <Col><FontAwesomeIcon icon={["fas", "calendar"]} /> {rowData.pathDetailsClient ? dayjs(rowData.pathDetailsClient.dateRequestClient).format("DD/MM/YYYY HH:mm") : "N/C"}</Col>
                    </Row>

                    {rowData.loading && <div className='loading-map-panel'><Loading  text={t("Chargement du détails de la mission")}/></div>}
                    {!rowData.loading && rowData.processedProducts && 
                    <Table striped bordered hover
                            onMouseLeave={async () => {await clearHighlightAssets() ;await highlightAssets(locationCodes, true, colorOptimized)}}
                            className={' ' + (showInitial ? "show-initial " : "") + (showOptimzed ? "show-optimized " : "")}>
                        <thead>
                            <tr>
                                <th className=''></th>
                                <th className='initial'>{checkComponent(showInitial, () => setShowInitial(!showInitial))} {t("Original")}</th>
                                <th className='initial'>{t("Qté")}</th>
                                <th className='optimized'>{checkComponent(showOptimzed, () => setShowOptimzed(!showOptimzed))} {t("Optimisé")}</th>
                                <th className='optimized'>{t("Qté")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData.pathDetailsClient.processedProductIds.map((processedClientProductId, i) => {

                            
                                const processedOriginal = rowData.processedProducts.find((p) => p.id == processedClientProductId);
                                const processedOptimized = rowData.processedProducts.find((p) => p.id == rowData.pathDetailsOptimized?.processedProductIds[i]);

                                const locationCodeOriginal =  processedOriginal?.address ? processedOriginal?.address : "";
                                const locationCodeOptimized = processedOptimized?.address ? processedOptimized?.address : "";
                                return <tr key={i}>
                                    <td ><small>{i + 1}</small></td>
                                    <td className='initial text-truncate' 
                                            onMouseEnter={async () => {await highlightAssets([locationCodeOriginal], true, colorOriginal)}} 
                                            onMouseLeave={async () => {await highlightAssets([locationCodeOriginal], false, colorOriginal)}}>
                                                {processedOriginal && renderLocationCode(processedOriginal)}</td>
                                    <td className='initial text-truncate'>{processedOriginal?.quantity}</td>
                                    <td className='optimized text-truncate' 
                                            onMouseEnter={async () => {await highlightAssets([locationCodeOptimized], true, colorOptimized)}} 
                                            onMouseLeave={async () => {await highlightAssets([locationCodeOptimized], false, colorOptimized)}}>
                                                {processedOptimized && renderLocationCode(processedOptimized)}</td>
                                    <td className='optimized text-truncate'>{processedOptimized?.quantity}</td>
                                </tr>
                            })}
                        </tbody>
                        <tfoot>
                        {<tr>
                                <th style={{fontWeight: "normal"}}className=''><small><FontAwesomeIcon icon={["fas", "flag-checkered"]} /></small> </th>
                                <th style={{fontWeight: "normal"}}className='text-center initial' colSpan={2}>{rowData.pathDetailsClient.distance.toLocaleString("fr-FR")} {t("mètres")}</th>
                                <th style={{fontWeight: "normal"}}className='text-center optimized' colSpan={2}>{rowData.pathDetailsOptimized?.distance.toLocaleString("fr-FR")} {t("mètres")}</th>
                        </tr>}
                        {<tr>
                                <th className=''><small><FontAwesomeIcon icon={["fas", "percent"]} /></small> </th>
                                <th className='text-center' colSpan={4}>
                                    {
                                        rowData.pathDetailsOptimized ? <>{computeGainPourcent(rowData.pathDetailsClient.distance, rowData.pathDetailsOptimized.distance)}%</>
                                        :<>N/C</>
                                    }
                                    
                                </th>
                        </tr>}
                        </tfoot>
                    </Table>}
                </Accordion.Body>
            </Accordion.Item>
                
        })}
        {loading && <div className='loading-missions'><Loading fullScreen text={t("Chargement des missions")}/></div>}
    </Accordion>

    {missions && <div className='mt-2 text-center'>
            <PaginationRender currentPage={missions.currentPage} totalPages={missions.totalPages} onChangePage={changePage} pageSize={10}/>
            <div>
                {missions.totalPages > 0 ? 
                <small className='text-muted'>{t("Page")} {missions.currentPage} {"/"} {missions.totalPages}</small>:
                <small className='text-muted'>{!loading && t("Aucun résultat")}</small>}
            </div>
        </div>}

    </>)
}
export default MiddlewarePathCompareRow